import React from 'react';
import { Cushion, LinearGradient, Rectangle, Space, Shadow, Typography } from '@pitchero/react-ui';
import Icon from '../icon';
import { Trans } from '../../lib/i18n';

const LiveIndicator = () => (
  <Shadow shadow="message">
    <Rectangle radius="rounded">
      <Cushion vertical="xxsmall" horizontal="xsmall">
        <LinearGradient
          component="div"
          angle={90}
          stops={[
            [0, '#F15E64'],
            ['100%', '#F67D82'],
          ]}
          style={{ lineHeight: '11px' }}
        >
          <Space right="xxsmall">
            <Icon name="play" preset="tag--small" color="white" topOffset={1} />
          </Space>
          <Typography preset="tag--small" color="white">
            <Trans i18nKey="common:live_indicator_label">Live</Trans>
          </Typography>
        </LinearGradient>
      </Cushion>
    </Rectangle>
  </Shadow>
);

export default LiveIndicator;
